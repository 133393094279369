@import '@hiredigital/ui/variables';
@import '../variables';

$gray-bg: $neutral-100;
$section-border-color: #2c2c2c; //$neutral-200
$neutral-bg-color: #f6f6f6;

.baseLayout,
.scheduleCallSection.isStandalonePage {
  font-family: $font-fam !important;

  input,
  label,
  textarea {
    font-family: $font-fam !important;
  }

  // a {
  //   text-decoration: none;

  //   &:hover {
  //     text-decoration: underline;
  //   }
  // }
}

@mixin section-border {
  position: relative;
  // &:after {
  //   content: '';
  //   position: absolute;
  //   bottom: -10px;
  //   left: 0;
  //   width: 100px;
  //   border-top: 5px solid $black;
  // }

  &:before {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 100%;
    border-top: 1px solid $section-border-color;
  }
}

.container {
  width: auto !important;
  padding-left: 75px;
  padding-right: 75px;

  @include for-size(tablet-landscape-down) {
    padding-left: 0;
    padding-right: 0;
  }

  @include for-size(phone-only) {
    padding-left: 0;
    padding-right: 0;
  }
}

.heroSection {
  // padding-top: 300px;
  // padding-bottom: 300px;
  // position: relative;
  // &:after {
  //   position: absolute;
  //   bottom: 0;
  //   width: 100%;
  //   content: '';
  //   border-bottom: 1px solid $neutral-800;
  // }
  @include section-border;
}

.title {
  font-family: $font-fam;
  // font-size: $size-10;
  // line-height: $line-height-10;
  // if same font
  font-size: $size-11;
  line-height: $line-height-11;

  font-weight: $fw-3;
  color: $black;
  // max-width: 700px;
  margin-bottom: 16px;
  white-space: pre-line;

  @include for-size(phone-only) {
    font-size: $size-9;
    line-height: $line-height-9;
  }
}

.description {
  font-family: $font-fam;
  font-size: $size-8;
  line-height: $line-height-8 * 1.2; // add 20%
  font-weight: $fw-1;
  padding-block: 30px;

  @include for-size(phone-only) {
    font-size: $size-6;
    line-height: $line-height-6 * 1.2; // add 20%
  }
}

.ctaImageContainer,
.featuredImageContainer {
  position: relative;
  overflow: hidden;
  border-radius: 15px;
}

.featuredImageContainer {
  margin-top: 30px;
}

.ctaImageContainer {
  @include for-size(tablet-landscape-down) {
    height: 300px;
  }

  @include for-size(phone-only) {
    height: 200px;
  }
}

.ctaImage,
.featuredImage {
  object-fit: cover;
  overflow: hidden;
  position: static !important;
  border-radius: 15px;
  width: 100%;
  height: 100%;
}

.featuredVideo {
}

////////////////////////////////////////////////////////////

.agencyLogoContainer {
}

.agencyLogoSection {
  // padding-top: 300px;
  // padding-bottom: 300px;
  @include section-border;
}

.agencyLogoTitle {
  font-family: $font-fam;
  font-size: $size-8;
  line-height: $line-height-8;
  // max-width: 800px;
  margin-bottom: 64px;
}

.agencyLogoLogosContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  grid-row-gap: 30px;
  grid-column-gap: 30px;

  @include for-size(tablet-landscape-down) {
    grid-template-columns: repeat(3, 1fr);
  }

  @include for-size(phone-only) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.agencyLogo {
  margin: auto;
  height: 75px;
  width: 150px;
  filter: brightness(50%);
}

.scheduleCallContainer {
  background-color: var(--accentColorSecondaryBackground, $accentBackground2);
}

.scheduleCallSection {
  $scheduleGap: 50px;

  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-template-areas:
    'title title'
    'subtitle subtitle'
    'form logos';
  gap: $scheduleGap;

  &.isStandalonePage {
    grid-template-areas: 'form logos';
  }

  @include for-size(tablet-landscape-down) {
    grid-template-columns: 1fr;
    grid-template-areas:
      'title'
      'subtitle'
      'form'
      'logos';

    &.isStandalonePage {
      grid-template-areas:
        'form'
        'logos';
    }
  }

  .agencyLogoTitle {
    color: var(--accentColorSecondaryText, $white);
    grid-area: title;
    font-size: $size-9;
    line-height: $line-height-9;
    font-weight: $fw-3;
    margin-bottom: 0;
  }

  .scheduleCallSectionSubtitle {
    color: var(--accentColorSecondaryText, $white);
    grid-area: subtitle;
    font-size: $size-7;
  }

  .scheduleCallForm {
    grid-area: form;
    justify-self: center;
    background-color: var(--accentColorSecondaryText, $white);
    border-radius: 15px;
    padding: 40px;
    margin: 0;
    height: fit-content;

    @include for-size(phone-only) {
      max-width: none;
    }

    .scheduleCallInput {
      border-radius: 30px;

      input,
      textarea {
        border-radius: 30px;
        padding-inline: 25px;
      }

      label {
        left: 25px !important;
      }

      :global(.phone-country__control) {
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;
      }

      :global(.phone-country__value-container) {
        padding-left: 16px;
      }

      .scheduleCallPhoneInput {
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;

        > input {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          padding-left: 12px;
          padding-right: 16px;
        }

        > label {
          left: 12px !important;
        }
      }
    }

    .scheduleCallButton {
      max-width: 200px;
      margin-inline: 0;
    }

    * {
      color: var(--accentColorSecondaryBackground, $accentBackground2);
    }
  }

  .scheduleCallLogosContainer {
    grid-area: logos;
    padding-left: $scheduleGap;
    border-left: 1px solid white;
    text-align: center;
    display: grid;
    grid-template-columns: 1fr 1fr;

    @include for-size(tablet-landscape-down) {
      padding-left: 0;
      border-left: none;
      grid-template-columns: 1fr 1fr 1fr;
    }

    @include for-size(phone-only) {
      grid-template-columns: 1fr 1fr;
    }
  }

  .scheduleCallLogoHeading {
    color: var(--accentColorSecondaryText, $white);
    font-size: $size-7;
    margin-bottom: 20px;
    grid-column: 1 / 3;

    @include for-size(tablet-landscape-down) {
      grid-column: 1 / 4;
    }

    @include for-size(phone-only) {
      grid-column: 1 / 3;
    }
  }

  .agencyLogo {
    filter: none;
  }

  .scheduleCallHubspotIframe {
    min-width: auto;
    min-height: auto;
    margin-top: 0;
  }
}

////////////////////////////////////////////////////////////

.testimonialContainer {
  &.Ad_Landing_Schedule_Call {
    background-color: var(--accentColorSecondaryBackground, $accentBackground2);
    background-size: cover;
    background-position: center;

    .sectionTitle,
    .testimonialTitle,
    .testimonialName,
    .testimonialRole,
    .testimonialCompany {
      color: var(--accentColorSecondaryText, $white);
    }

    .testimonialTitle {
      font-size: $size-6;
      line-height: $line-height-6 * 1.2;
    }

    .testimonialSection {
      display: grid;
      grid-template-areas:
        'title title'
        'blurb buttons';
      grid-template-columns: auto 300px;

      @include for-size(tablet-landscape-down) {
        grid-template-areas:
          'title'
          'blurb'
          'buttons';
        grid-template-columns: auto;
      }

      &::before {
        display: none;
      }

      .sectionTitle {
        grid-area: title;
      }

      .testimonialContent {
        grid-area: blurb;
        border-right: 2px solid #ffffff7f;
        padding-right: 50px;

        @include for-size(tablet-landscape-down) {
          border-right: none;
          padding-right: 0;
        }
      }

      .testimonialButtons {
        grid-area: buttons;
        padding: 50px 0 0 30px;

        @include for-size(tablet-landscape-down) {
          padding: 20px 0 0;
          margin-left: -10px;
        }
      }
    }
  }

  &.Home_Page_About_Us {
    .sectionTitle {
      margin-bottom: 70px;
    }

    .agencyLogo {
      height: auto;
      margin-bottom: 20px;
      // padding: 0 20px;
      // height: 100px;
      // width: 200px;
      filter: brightness(80%);
    }

    .testimonialTitle {
      // padding: 20px;
      font-size: $size-4;
      line-height: $line-height-4;
    }

    .testimonialGrid {
      display: grid;
      grid-row-gap: 60px;
      grid-column-gap: 100px;
      grid-template-columns: 1fr 1fr;

      @include for-size(phone-only) {
        grid-template-columns: 1fr;
      }

      .testimonial1 {
        grid-row: 1 / 4;

        @include for-size(tablet-landscape-down) {
          grid-row: auto;
          grid-column: 1 / 3;
        }

        @include for-size(phone-only) {
          grid-column: auto;
        }

        .testimonialTitle {
          font-size: $size-9;
          line-height: 1.25;
          // line-height: $line-height-9;

          @include for-size(desktop-down) {
            font-size: $size-8;
            // line-height: $line-height-8;
          }

          @include for-size(tablet-landscape-down) {
            font-size: $size-7;
            // line-height: $line-height-7;
          }

          @include for-size(phone-only) {
          }
        }
      }
    }
  }

  &.testimonialDefault {
    .testimonialTitle {
      padding-bottom: 40px;

      @include for-size(phone-only) {
        padding-bottom: 24px;
        font-size: $size-7;
        line-height: $line-height-7 * 1.3;
      }
    }
  }
}

.testimonialSection {
  // padding-top: 300px;
  // padding-bottom: 300px;
  ul {
    white-space: normal;
  }

  @include section-border;
}

.testimonialTitle {
  font-family: $font-fam;
  font-size: $size-9;
  line-height: $line-height-9 * 1.3;
  // font-weight: $fw-3;
  // margin-bottom: 64px;
}

.testimonialArrows {
  float: right;

  @include for-size(tablet-landscape-down) {
    float: none;
    display: block;
    text-align: center;
  }
}

.testimonialArrowLeft,
.testimonialArrowRight {
  min-width: 0;
  padding: 15px;
  margin: 5px;
}

.testimonialArrowLeft svg {
  rotate: -135deg !important;
}

.testimonialNameContainer {
  display: flex;
  // border: 5px solid $neutral-200;
  align-items: center;
  // background: $gray-bg;
  width: fit-content;

  @include for-size(phone-only) {
    display: grid;
    grid-template-columns: auto;
  }
}

.testimonialLogo {
  padding: 20px;
  padding-left: 40px;
  border-left: 1px solid $neutral-500;
  margin-left: 30px;

  @include for-size(phone-only) {
    border-left: none;
    margin-left: 0;
    padding: 0;
  }

  .agencyLogo {
    height: 35px;
    width: auto;
  }
}

.testimonialImage {
  height: 70px;
  border-radius: 100%;
  margin-right: 20px;
}

.testimonialNameContent {
  // border-left: 1px dotted $neutral-400;
  padding: 20px 0;
}

.testimonialName {
  font-family: $font-fam;
  font-size: $size-6;
  line-height: $line-height-6;
  font-weight: $fw-3;

  margin: 0;
  margin-bottom: 3px;
}

.testimonialRole,
.testimonialCompany {
  font-family: $font-fam;
  font-size: $size-3;
  line-height: $line-height-3;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  font-weight: $fw-2;
  color: $neutral-500;
  margin: 0;
}

////////////////////////////////////////////////////////////

.caseContainer {
}

.caseSection {
  // padding-top: 300px;
  // padding-bottom: 300px;
  @include section-border;
}

.sectionTitle {
  font-family: $font-fam;
  font-size: $size-9;
  line-height: $line-height-9;
  font-weight: $fw-3;
  margin-bottom: 48px;

  @include for-size(phone-only) {
    font-size: $size-8;
    line-height: $line-height-8;
    margin-bottom: 24px;
  }
}

.sectionDescription {
  margin-bottom: 30px;
  font-size: $size-5;
  line-height: $line-height-5;

  .talentContainer &,
  .cardContainer &,
  .ctaContainer & {
    font-size: $size-7 - 2px;
    line-height: $line-height-7 * 1.1;
  }
}

.caseItemContainer {
  margin-bottom: 40px;

  &:last-child {
    margin-bottom: 0;
  }
}

.caseItem {
  position: relative;
  min-height: 200px;
  display: flex;
  align-items: flex-end;
  padding: 40px;
  z-index: $z-base;

  @include for-size(phone-only) {
    padding: 20px;
  }

  .caseImage {
    position: absolute;
    object-fit: cover;
    border-radius: 15px;
    z-index: $z-background;
  }
}

.caseContentSection {
  z-index: $z-base;
  padding-right: calc(60px + 20vw);

  @include for-size(desktop-down) {
    padding-right: calc(60px + 5vw);
  }

  @include for-size(phone-only) {
    padding-right: 70px;
  }
}

.caseLink {
  color: $white;
  text-decoration: none;
}

.caseToggle {
  z-index: $z-base;
  position: absolute;
  height: 60px;
  width: 60px;
  bottom: 40px;
  right: 40px;
  color: var(--accentColorSecondaryBackground, $accentBackground2);
  background-color: var(--accentColorPrimaryBackground, $accentBackground1);
  cursor: pointer;
  font-size: 60px;
  /* display: flex; */
  display: none;
  align-items: center;
  justify-content: center;
  line-height: 0;
  user-select: none;
  /* Standard syntax */
  border-radius: 100%;

  @include for-size(phone-only) {
    bottom: 20px;
    right: 20px;
  }

  > svg {
    width: 60%;
  }
}

.caseTitle {
  font-family: $font-fam;
  font-size: $size-8 * 1.2;
  line-height: $line-height-8 * 1.2;
  font-weight: $fw-3;
  color: currentColor;
  z-index: $z-base;
  // max-width: 500px;

  @include for-size(phone-only) {
    font-size: $size-7;
    line-height: $line-height-7;
  }
}

.caseReadMore {
  font-family: $font-fam;
  color: currentColor;
  font-weight: $fw-3;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.caseDetails {
  z-index: $z-behind;
  // background-color: $gray-bg;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr auto;
  gap: 30px 30px;
  grid-template-areas:
    'description challenge solution'
    'description team team';

  @include for-size(tablet-landscape-down) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    grid-template-areas:
      'description description'
      'challenge solution'
      'team team';
  }
  @include for-size(phone-only) {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      'description'
      'challenge'
      'solution'
      'team';
  }

  max-height: 0;
  padding-inline: 30px;
  padding-block: 0;
  opacity: 0;
  overflow: hidden;
  transition-property: max-height, padding-block, opacity;
  transition-duration: 0.5s;
  transition-timing-function: $cubic;

  &.isDetailsVisible {
    max-height: 400px;
    padding-block: 30px;
    opacity: 1;

    @include for-size(tablet-landscape-down) {
      max-height: 700px;
    }

    @include for-size(phone-only) {
      max-height: 1000px;
    }
  }
}

.caseDescription {
  font-family: $font-fam;
  font-size: $size-5;
  line-height: $line-height-6;
  grid-area: description;
}

.caseChallenge {
  grid-area: challenge;
}

.caseSolution {
  grid-area: solution;
}

.caseTeamStructure {
  grid-area: team;
}

.caseTeamStructureContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px 30px;

  @include for-size(phone-only) {
    grid-template-columns: 1fr;
  }
}

.caseHeader {
  font-family: $font-fam;
  font-size: $size-3;
  line-height: $line-height-3;
  text-transform: uppercase;
  font-weight: $fw-2;
  color: var(--accentColorSecondaryBackground, $accentBackground2);
  margin-bottom: 8px;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    // border-bottom: 1px dotted $neutral-500;
    top: -5px;
    right: 0;
    width: 100%;
  }
}

.caseContent {
  font-family: $font-fam;
  font-size: $size-4;
  line-height: $line-height-4;

  strong {
    color: $neutral-400;
  }

  p {
    font-size: $size-2;

    &:first-child {
      font-size: $size-3;
    }
  }
}

////////////////////////////////////////////////////////////

.cardContainer {
  &.Expertise,
  &.Solutions {
    .cardRow {
      flex-direction: column;

      .card {
        background-color: transparent;
        margin-left: 0;
        padding-inline: 0;

        .cardIcon {
          width: 75px;
          padding: 15px;
          box-sizing: border-box;
          background-color: var(--accentColorPrimaryBackground, $accentBackground1);
          border-radius: 15px;
        }

        .sidebarTitle {
          text-transform: uppercase;
          // font-size: 0.9rem;
          margin-block: 10px;
          color: var(--accentColorSecondaryBackground, $accentBackground2);
        }

        .sidebarList {
          columns: 2;
          overflow: hidden;

          &.fewItemsOnly {
            column-fill: auto;
            max-height: 130px;
          }

          > p {
            break-inside: avoid-column;
            text-indent: -10px;
            padding-left: 10px;
          }
        }

        display: grid;
        grid-template-columns: 75px 1fr 2fr;
        column-gap: 30px;

        > img:nth-child(1) {
          grid-area: icon;
        }

        > h3:nth-child(2) {
          grid-area: title;
        }

        > p:nth-child(3) {
          grid-area: desc;
        }

        > div:nth-child(4) {
          grid-area: sidebar;
          margin-left: 50px;
        }
      }
    }
  }

  &.Expertise {
    .cardRow {
      .card {
        border-top: 1px solid $neutral-400;
        border-radius: 0;
        padding-block: 70px;

        &:first-child {
          border-top: none;
          padding-top: 30px;
        }

        grid-template-areas:
          'icon title sidebar'
          'desc desc  sidebar';

        @include for-size(phone-only) {
          grid-template-columns: 75px 1fr;
          grid-template-areas:
            'icon title'
            'desc desc'
            'sidebar sidebar';

          > div:nth-child(4) {
            margin-left: 0;
            margin-top: 10px;
          }
        }
      }
    }
  }

  &.Solutions {
    .sectionDescription {
      margin-bottom: 50px;
    }

    .cardRow {
      .card {
        border-top: 1px solid $neutral-200;
        border-radius: 0;
        grid-template-areas: 'icon title desc';

        @include for-size(phone-only) {
          grid-template-columns: 75px 1fr;
          grid-template-areas:
            'icon title'
            'desc desc';
          row-gap: 20px;
        }

        > * {
          margin-bottom: 0;
          align-self: center;
        }
      }
    }
  }

  &.Proposed_Team {
    .cardRow {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 5px;
      border-radius: 15px;
      overflow: hidden;

      @include for-size(phone-only) {
        grid-template-columns: 1fr;
      }

      .card {
        margin-left: 0;
        border-radius: 0;
        min-width: auto;
        padding: 60px;

        @include for-size(tablet-landscape-down) {
          padding: 40px;
        }

        .cardIcon {
          height: 60px;
          margin-bottom: 40px;
        }
      }
    }
  }

  &.Expert_Talents {
    .cardRow {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 40px;

      @include for-size(desktop-down) {
        grid-template-columns: 1fr 1fr 1fr;
      }

      @include for-size(tablet-landscape-down) {
        grid-template-columns: 1fr 1fr;
        gap: 30px;
      }

      @include for-size(phone-only) {
        grid-template-columns: 1fr;
        gap: 20px;
      }

      .card {
        margin-left: 0;
        padding: 0;
        overflow: hidden;
        --cardMargin: 30px;

        @include for-size(phone-only) {
          display: grid;
          grid-template-columns: auto 1fr;

          .cardIcon {
            grid-row: 1 / 3;
          }

          .cardDesc {
            grid-column: 1 / 3;
          }
        }

        .cardIcon {
          max-width: 100%;
          max-height: 100%;
          margin: 0;

          @include for-size(phone-only) {
            border-radius: 100%;
            margin: var(--cardMargin) 0 0 var(--cardMargin);
          }

          @include for-size(tablet-portrait-up) {
            width: auto;
            height: auto;
          }
        }

        .cardTitle {
          margin: var(--cardMargin);
          margin-bottom: 0;
        }

        .cardSubtitle {
          margin: 10px var(--cardMargin) 20px;
          // font-weight: $fw-1;
        }

        .cardDesc {
          margin: var(--cardMargin);
          margin-top: 0;

          @include for-size(phone-only) {
            margin-top: 20px;
          }
        }

        .cardLogo {
          display: block;
          margin-top: var(--cardMargin);
          border-top: 1px solid $white;
          padding-top: var(--cardMargin);
          filter: brightness(50%);

          > img {
            height: 35px;

            @include for-size(phone-only) {
              height: 25px;
            }
          }
        }
      }
    }
  }

  &.Digital_Expertise {
    background-color: var(--accentColorPrimaryBackground, $accentBackground1);

    .cardSection::before {
      display: none;
    }

    .cardSection .sectionTitle {
      margin-bottom: 60px;

      @include for-size(phone-only) {
        margin-bottom: 50px;
      }
    }

    .cardRow {
      flex-direction: column;

      .card {
        flex-direction: row;
        background-color: transparent;
        border-top: 1px solid $neutral-600;
        border-radius: 0;
        margin-left: 0;
        padding: 40px 0;

        @include for-size(phone-only) {
          flex-direction: column;
        }

        .cardTitle {
          min-width: 30%;
          margin-right: 15px;
        }

        .cardDesc .cardLink {
          text-decoration: underline;
        }

        p {
          white-space: pre-line;
        }
      }
    }
  }

  &.Engagement_Models,
  &.Industry_Engagement_Models {
    .cardRow {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 30px;

      .card {
        padding: 40px;
      }

      @include for-size(tablet-landscape-down) {
        grid-template-columns: 1fr 1fr;
      }

      @include for-size(phone-only) {
        grid-template-columns: 1fr;
      }
    }

    .card {
      margin-left: 0;
    }
  }

  &.Industry_Engagement_Models {
    background-color: var(--accentColorPrimaryBackground, $accentBackground1);

    .cardSection::before {
      display: none;
    }
  }
}

.cardRowContainer {
  overflow: auto;
  scrollbar-width: none;
}

.cardRow {
  display: flex;
  // display: grid;
  // display: grid;
  // grid-template-columns: 1fr 1fr 1fr;
  // grid-template-rows: 1fr;
  // gap: 30px 30px;
}

.cardSection {
  // padding-top: 300px;
  // padding-bottom: 300px;
  @include section-border;
}

.card {
  flex-basis: 33%;
  display: flex;
  flex-direction: column;
  padding: 30px;
  background-color: $neutral-bg-color;
  min-width: 250px;
  border-radius: 15px;

  & ~ & {
    margin-left: 30px;
  }
}

.cardIcon {
  height: 75px;
  // width: 75px;
  margin-right: auto;
  margin-bottom: 30px;
}

.cardTitle {
  font-size: $size-7;
  margin-bottom: 16px;
  white-space: pre-line;
}

.cardDesc {
  font-size: $size-4;
  line-height: $line-height-4;
  // max-width: 600px;
}

.cardLink {
  display: block;
  margin-top: 20px;
  text-decoration: none;
  font-weight: bold;
  color: inherit;
  &:hover {
    text-decoration: underline;
  }
}

.cardCtaButton {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20px;

  @include for-size(phone-only) {
    flex-direction: column;
    align-items: flex-start;
  }

  > span {
    font-size: $size-7 - 2px;
    line-height: $line-height-7 * 1.1;
  }

  .button {
    margin-inline: 0;
  }
}

////////////////////////////////////////////////////////////

.talentContainer {
  background-color: var(--accentColorSecondaryBackground, $accentBackground2);
  color: var(--accentColorSecondaryText, $white);
  overflow: hidden;

  .sectionTitle,
  .sectionDescription {
    color: var(--accentColorSecondaryText, $white);
  }
}

.talentSection {
  // overflow: hidden;
}

.talentRowContainer {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  position: relative;
  left: 50%;
  margin-block: 90px;
  margin-left: -50vw;
  width: 100vw;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.talentRow {
  background-color: var(--accentColorSecondaryText, $white);
  color: var(--accentColorSecondaryBackground, $accentBackground2);
  margin: 10px;
  border-radius: 8px;
  overflow: hidden;
  min-width: 190px;
}

.talentPicture {
  width: 100%;
  // border-top-left-radius: 8px;
  // border-top-right-radius: 8px;
}

.talentHeading {
  margin: 15px 15px 5px;
  font-size: $size-3;
  // line-height: $line-height-3;
  font-weight: $fw-3;
  // font-size: 0.8rem;
}

.talentContent {
  margin: 0 15px 15px;

  font-size: $size-1;
  line-height: $line-height-1 * 0.7;
}

.talentButtons {
  margin-top: 30px;
}

////////////////////////////////////////////////////////////

.insightRowContainer {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;

  @include for-size(tablet-landscape-down) {
    grid-auto-flow: row;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 30px;
    grid-column-gap: 30px;
  }

  @include for-size(phone-only) {
    grid-template-columns: 1fr;
  }
}

.insightRow {
  border-left: 1px solid $neutral-400;
  padding: 0 30px 20px 30px;
  position: relative;
  text-decoration: none;
  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
  }

  &:hover {
    .insightHeading {
      text-decoration: none;
    }
    .insightContent {
      text-decoration: underline;
    }
  }
}

.insightHeading {
  font-size: $size-3;
  line-height: $line-height-3;
  font-weight: $fw-3;
  text-transform: uppercase;
  text-decoration: none;
  // padding-bottom: 10px;
  color: var(--accentColorSecondaryBackground, $accentBackground2);

  // &.Story {
  //   color: var(--accentColorSecondaryBackground, $accentBackground2);
  // }

  // &.Event {
  //   color: var(--accentColorPrimaryBackground, $accentBackground1);
  // }

  // &.Press {
  //   color: #95a5b6;
  // }
}

.insightContent {
  font-size: $size-4;
  line-height: $line-height-4;
  text-decoration: none;
  color: black;
}

////////////////////////////////////////////////////////////

.learnContainer {
  background-color: var(--accentColorSecondaryBackground, $accentBackground2);

  .learnGrid {
    display: grid;
    grid-template-columns: 5fr 4fr;
    column-gap: 50px;

    @include for-size(tablet-landscape-down) {
      grid-template-columns: 1fr;
      row-gap: 20px;
    }
  }

  &,
  .sectionTitle,
  .sectionSubtitle {
    color: var(--accentColorSecondaryText, $white);
  }

  .sectionSubtitle {
    font-size: $size-7;
  }

  .sectionDescription > * {
    margin: 20px auto;
    line-height: 26px;
    font-size: $size-4;
    line-height: $line-height-4;
  }

  .learnRowContainer {
    background-color: var(--accentColorSecondaryText, $white);
    color: $black;
    padding: 20px 30px 30px;
    border-radius: 15px;
  }

  .learnRowTitle {
    text-transform: uppercase;
    color: var(--accentColorSecondaryBackground, $accentBackground2);
    font-size: 0.8rem;
    font-weight: bold;
    letter-spacing: 0.1em;
  }

  .learnSpeakerName {
    font-size: 1.2rem;
    font-weight: bold;
    margin-top: 20px;
  }

  .learnSpeakerRole,
  .learnSpeakerCompany {
    text-transform: uppercase;
    font-size: 0.9rem;
    margin-top: 5px;
  }

  .learnSpeakerBlurb {
    margin-top: 20px;
  }

  .learnWebinar {
    border-radius: 15px;
    overflow: hidden;
    background-color: var(--accentColorSecondaryText, $white);
    color: $black;
    padding: 0;
    margin-inline: 30px;
    height: fit-content;

    @include for-size(desktop-down) {
      margin-inline: 0;
    }

    .webinarInput {
      margin: 20px 30px;
      width: auto;
      border-radius: 999px;

      > input {
        border-radius: 999px;
        padding-inline: 25px;
      }

      > label {
        left: 25px !important;
      }
    }

    .button {
      margin: 0 30px;
    }

    .webinarMessage {
      margin: 0 30px;
      font-weight: $fw-1;
    }
  }

  .webinarImageContainer {
    position: relative;
    height: 30vw;
    max-height: 350px;
    background-color: var(--accentColorSecondaryText, $white);
    background-size: cover;
    background-position: center;
    // margin-bottom: 50px;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      opacity: 0.5;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
    }
  }

  .webinarTitle {
    font-size: $size-7;
    // margin-top: -70px;
    color: var(--accentColorSecondaryText, $white);
    padding: 20px 30px;
    // margin-bottom: 50px;
    position: absolute;
    bottom: 0;
    z-index: 1;
  }

  .webinarPrivacyAgreement > * {
    font-size: 0.8rem;
    line-height: normal;
    color: $neutral-400;
    padding: 10px;
    margin: 20px;
    a {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

////////////////////////////////////////////////////////////

.ctaContainer {
  &.Trained_by_Experts {
    .ctaSection {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 100px;

      @include for-size(tablet-landscape-up, 1px) {
        padding-right: 0;
      }

      @include for-size(tablet-landscape-down) {
        grid-template-columns: 1fr;
        grid-template-areas:
          'image'
          'text';
        div:first-child {
          grid-area: text;
        }
        div:last-child {
          grid-area: image;
          margin-bottom: 50px;
        }
      }
    }

    .ctaButtons {
      max-width: 300px;
    }

    img {
      border-radius: 15px; // 15px 0 0 15px;
      float: right;

      @include for-size(tablet-landscape-down) {
        float: none;
        width: 100%;
      }
    }
  }

  &.Prefer_On_Demand {
    .ctaSection {
      background: var(--accentColorSecondaryBackground, $accentBackground2);
      background-size: cover;
      background-position: center;

      @include for-size(tablet-landscape-up, 1px) {
        padding: 60px;
        border-radius: 15px;
      }
    }

    &,
    .sectionTitle,
    .sectionDescription {
      color: var(--accentColorSecondaryText, $white);
    }
  }
}

////////////////////////////////////////////////////////////

.caseMetricSection {
  position: relative;
  // max-width: 900px;
  margin: auto;
}

.caseMetric {
  display: grid;
  grid-column-gap: 50px;
  grid-row-gap: 60px;
  grid-auto-columns: 1fr 4fr;
  grid-auto-flow: column;
  padding: 20px 0;

  & ~ & {
    border-top: 1px solid $neutral-400;
  }

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }

  @include for-size(tablet-landscape-down) {
    grid-auto-columns: 1fr 1.5fr;
  }

  @include for-size(phone-only) {
    grid-auto-flow: row;
    grid-row-gap: 0;
    grid-column-gap: 0;
  }
}

.metricValue {
  font-size: $size-10;
  line-height: $line-height-10;
  color: var(--accentColorPrimaryBackground, $accentBackground1);
  font-weight: $fw-3;
  // @include for-size(phone-only) {
  //   font-size: $size-9;
  //   line-height: $line-height-9;
  //   // margin-bottom: 0;
  // }
}

.metricDescription {
  font-size: $size-7;
  line-height: $line-height-10;
  font-weight: $fw-2;
  @include for-size(phone-only) {
    font-size: $size-6;
    line-height: $line-height-6;
    // margin-bottom: 0;
  }
}

////////////////////////////////////////////////////////////

.mediaIndicator {
  display: none;
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 9999;
  border-radius: 3px;
  padding: 2px 4px;
  background: red;
  color: yellow;
  font-weight: $fw-2;
  opacity: 0.9;

  &::after {
    content: 'normal';

    @include for-size(desktop-down) {
      content: 'desktop-down';
    }

    @include for-size(tablet-landscape-down) {
      content: 'tablet-landscape-down';
    }

    @include for-size(phone-only) {
      content: 'phone-only';
    }
  }
}
